<template>
    <div class="col-10 offset-1">
        <div class="col settings-button">
        </div>

        <div class="text-center pb-4">
            <img class="rounded" src="https://twitch.microtaur.com/static/logo.jpg" alt="Microtaur">
        </div>

        <h1>Admin panel</h1>
        <hr>
        <div class="d-flex justify-content-between align-items-center">
            <h2>Statistics</h2>
            <h3 v-if="!isFetching"><ion-icon name="refresh-circle-outline" @click="isFetching || fetchAdminStatistics()"></ion-icon></h3>
            <h3 v-else>
                <div class="spinner-grow text-primary" role="status"></div>
            </h3>
        </div>
        <div class="mb-3 text-center">
          <div class="col">

            <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                    <h4 class="my-0 fw-normal">Sessions summary</h4>
                </div>

                <div>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Total sessions</th>
                                        <th>Active sessions</th>
                                        <th>Sessions today</th>
                                        <th>Sessions last hour</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ sessions.all }}</td>
                                        <td>{{ sessions.active }}</td>
                                        <td>{{ sessions.today }}</td>
                                        <td>{{ sessions.last_hour }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 class="card-header border-top">Last sessions [50]</h5>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead class="table-dark">
                                    <tr>
                                        <th>#</th>
                                        <th>Started by</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Events</th>
                                        <th>Active</th>
                                        <th>Watch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="session in sessions.last_sessions" :key="session.session_id">
                                        <td>{{ session.session_id }}</td>
                                        <td>{{ session.user_name }}</td>
                                        <td>{{ formatDate(session.start_time) }}</td>
                                        <td>
                                            <span v-if="!session.active">{{ formatDate(session.end_time) }}
                                                <span v-if="session.timeout" class="badge bg-warning text-dark">T</span>
                                            </span>
                                            <span v-else>
                                                ⏳ {{ calculateTimeDifference(session.start_time, session.end_time) }}
                                            </span>
                                        </td>
                                        <td>{{ session.events }}</td>
                                        <td>{{ session.active ? '✅' : '❌' }}</td>
                                        <td><button class="button-link" @click.prevent="twitchRedirect(session.user_twitch_id)">📺</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 class="card-header border-top">Last admin sessions [5]</h5>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead class="table-dark">
                                    <tr>
                                        <th>#</th>
                                        <th>Started by</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Events</th>
                                        <th>Active</th>
                                        <th>Watch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="session in sessions.last_admin_sessions" :key="session.session_id">
                                        <td>{{ session.session_id }}</td>
                                        <td>{{ session.user_name }}</td>
                                        <td>{{ formatDate(session.start_time) }}</td>
                                        <td>
                                            <span v-if="!session.active">{{ formatDate(session.end_time) }}
                                                <span v-if="session.timeout" class="badge bg-warning text-dark">T</span>
                                            </span>
                                            <span v-else>
                                                ⏳ {{ calculateTimeDifference(session.start_time, session.end_time) }}
                                            </span>
                                        </td>
                                        <td>{{ session.events }}</td>
                                        <td>{{ session.active ? '✅' : '❌' }}</td>
                                        <td><button class="button-link" @click.prevent="twitchRedirect(session.user_twitch_id)">📺</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>



                        <h5 class="card-header border-top">Events summary</h5>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Total Events</th>
                                        <th>Events Today</th>
                                        <th>Events Last Hour</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ events.all }}</td>
                                        <td>{{ events.today }}</td>
                                        <td>{{ events.last_hour }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5 class="card-header border-top">Most popular events</h5>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead class="table-dark">
                                    <tr>
                                        <th>Event Name</th>
                                        <th>Occurrences</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="event in events.most_popular" :key="event.name">
                                        <td>{{ event.name }}</td>
                                        <td>{{ event.name_count }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 class="card-header border-top">Users summary</h5>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Total Users</th>
                                        <th>Users Joined Today</th>
                                        <th>Users Joined Last Hour</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ users.all }}</td>
                                        <td>{{ users.joined_today }}</td>
                                        <td>{{ users.joined_last_hour }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 class="card-header border-top">Most active users by session count</h5>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead class="table-dark">
                                    <tr>
                                        <th>User</th>
                                        <th>Sessions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in users.most_active_by_sessions" :key="user.user__id">
                                        <td>{{ user.user__user__username }}</td>
                                        <td>{{ user.session_count }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 class="card-header border-top">Most active users by event count</h5>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <thead class="table-dark">
                                    <tr>
                                        <th>User</th>
                                        <th>Events</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in users.most_active_by_events" :key="user.id">
                                        <td>{{ user.user__username }}</td>
                                        <td>{{ user.event_count }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


              </div>
            </div>

          </div>
        </div>

    </div>
</template>

<script>
import AdminService from '@/services/admin.service.js';
import TwitchService from '@/services/twitch.service.js';

export default {
    name: 'AdminStatistics',

    data () {
        return {
            sessions: {},
            events: {},
            users: {},
            isFetching: false,
        }
    },

    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('en-UK', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).format(date);
        },

        calculateTimeDifference(timestamp1, timestamp2) {
            const date1 = new Date(timestamp1);
            const date2 = new Date(timestamp2);

            const differenceInMilliseconds = date2 - date1;

            const seconds = Math.floor(differenceInMilliseconds / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            const hoursLeft = hours % 24;
            const minutesLeft = minutes % 60;
            const secondsLeft = seconds % 60;

            let result = "";
            if (days > 0) result += `${days}d `;
            if (hoursLeft > 0 || days > 0) result += `${hoursLeft}h `;
            if (minutesLeft > 0 || hoursLeft > 0 || days > 0) result += `${minutesLeft}m `;
            result += `${secondsLeft}s`;

            return result;
        },

        async twitchRedirect(id) {
          try {
            const response = await TwitchService.getWatchUrl(id);
            window.open(response.data.url, '_blank');
          } catch (error) {
            console.error('Failed to redirect:', error);
          }
        },

        fetchAdminStatistics() {
            if (this.isFetching) return;
            this.isFetching = true;

            AdminService.getAdminStatistics()
            .then(data => {
                data.sessions.last_sessions.sort((a, b) => b.active - a.active);

                this.sessions = data.sessions;
                this.events = data.events;
                this.users = data.users;
                this.isFetching = false;
            })
            .catch(() => {
                AdminService.removeAdmin();
                this.$root.$emit('FooterUpdateEvent');
                this.$router.push({ path: '/' });
                this.isFetching = false;
            });
        }
    },

    mounted: function() {
        this.fetchAdminStatistics();
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },
}
</script>

<style scoped>
.settings-button {
    padding-left: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.rounded {
    border-radius: 40px;
}

.button-link {
    border: 0;
    margin: 0;
    padding: 0;
    background: 0;
}
</style>