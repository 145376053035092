<template>


<div class="mb-3 text-center">

  <div class="col">

    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">Twitch</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title mb-4">Status: <small class="text-muted fw-light">
            <div v-if="connection.status === null" class="spinner-grow text-primary" role="status">
                <span class="sr-only"></span>
            </div>
            <span v-else>
                <span v-if="connection.status == true" class="alert alert-success">ok</span>
                <span v-else class="alert alert-warning">not connected</span>
            </span>
        </small></h1>

        <div v-if="connection.status">
            <ul class="list-unstyled mt-3 mb-4">
              <li><b>Twitch username:</b> {{ connection.username }}</li>
              <li><b>Twitch id: </b>
                <span v-if="connection.userId !== null">{{ connection.userId }}</span>
                <span v-else>unknown</span>
                </li>
            </ul>

            <a href="https://twitch.microtaur.com/builds/versions/latest/installer.exe" class="btn btn-success">Download Client</a>
        </div>

        <a v-if="!connection.status && connection.status !== null"
            
            :href="'https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=f7kkzh0fsyeukb08yg791qh847aivx&scope=channel:moderate+chat:edit+chat:read&state=' + connection.stateCode + '&redirect_uri=' + connection.redirectUrl"

            type="button" class="w-50 btn btn-lg btn-outline-primary"
        >
            Connect
        </a>

        <b-alert class="mt-3" v-model="connection.twitchIdUnavailable" variant="danger">This Twitch account ({{ connection.alreadyUsedUsername }}) is already integrated with another user!</b-alert>

      </div>
    </div>
  </div>
</div>

</template>

<script>
    import Vue from 'vue'
    import TwitchService from '@/services/twitch.service.js';
    import { BAlert } from 'bootstrap-vue'

    Vue.component('b-alert', BAlert);

    export default {
        name: 'TwitchConnect',

        data () {
            return {
                connection: {
                    status: null,
                    login: null,
                    username: null,
                    userId: null,
                    stateCode: TwitchService.getStateCode(),
                    redirectUrl: TwitchService.getRedirectUrl(),
                    twitchIdUnavailable: false,
                    alreadyUsedUsername: "",
                },
            }
        },

        mounted: function() {

            handleQuery(this, this.$route.query)
                .then(() => {
                    updateStatus(this);
                })
                .catch(() => {
                    if (this.$route.path == '/connect') {
                        this.$router.replace({ path: '/' });
                    }
                    updateStatus(this);
                });

        }
    }


    function updateStatus(self) {

        TwitchService.getStatus()
        .then(response => {
            if (response.data['token'] !== undefined) {
                self.connection.status = true;
                self.connection.username = response.data['login'];
                self.connection.userId = response.data['user_id'];
            }
        })
        .catch(error => {
            if (error.response.status == 404) {
                self.connection.status = false;
            }
            if (error.response.status == 403) {
                self.connection.status = false;
                self.connection.twitchIdUnavailable = true;
                self.connection.alreadyUsedUsername = error.response.data['login'];
            }
        });

    }


    function handleQuery(self, params) {
        const sc = TwitchService.getStateCode();
        if (sc === null) {
            return Promise.reject();
        }

        if (sc != params['state']) {
            return Promise.reject();
        }

        const code = params['code'];
        if (code === undefined) {
            return Promise.reject();
        }

        TwitchService.clearStateCode();

        // Send auth request to the backend
        return TwitchService.sendAuth(code);
    }

</script>