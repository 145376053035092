<template>
  <div class="col-10 offset-1">
    <div class="d-flex justify-content-between align-items-center">
        <h2>System Information</h2>
        <h3 v-if="!isFetching"><ion-icon name="refresh-circle-outline" @click="isFetching || fetchSystemStatistics()"></ion-icon></h3>
        <h3 v-else>
            <div class="spinner-grow text-primary" role="status"></div>
        </h3>
    </div>

    <div class="card mb-3">
      <h5 class="card-header">CPU</h5>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Total Cores</th>
              <th scope="col" v-for="n in systemInfo.cpu_cores" :key="'core-header-' + n">
                Core {{ n }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ systemInfo.cpu_cores }}</td>
              <td v-for="(usage, index) in systemInfo.cpu_usage_per_core" :key="'cpu-usage-' + index">
                {{ usage }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 class="card-header border-top">Memory</h5>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>Total Memory (GB)</th>
              <th>Used Memory (GB)</th>
              <th>Free Memory (GB)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ bytesToGigabytes(systemInfo.memory_total) }}</td>
              <td>{{ bytesToGigabytes(systemInfo.memory_used) }}</td>
              <td>{{ bytesToGigabytes(systemInfo.memory_free) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 class="card-header border-top">Disk</h5>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>Total Disk (GB)</th>
              <th>Used Disk (GB)</th>
              <th>Free Disk (GB)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ bytesToGigabytes(systemInfo.disk_total) }}</td>
              <td>{{ bytesToGigabytes(systemInfo.disk_used) }}</td>
              <td>{{ bytesToGigabytes(systemInfo.disk_free) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h5 class="card-header border-top">Network</h5>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>Sent Data (MB)</th>
              <th>Received Data (MB)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ bytesToMegabytes(systemInfo.network_sent) }}</td>
              <td>{{ bytesToMegabytes(systemInfo.network_recv) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import AdminService from '@/services/admin.service.js';

export default {
  name: 'AdminSystem',
  data() {
    return {
      systemInfo: {},
      isFetching: false
    };
  },

  methods: {
    bytesToGigabytes(bytes) {
      return (bytes / 1024 ** 3).toFixed(2);
    },
    bytesToMegabytes(bytes) {
      return (bytes / 1024 ** 2).toFixed(2);
    },
    fetchSystemStatistics() {
        if (this.isFetching) return;
        this.isFetching = true;

        AdminService.getSystemStatistics()
        .then(data => {
            this.systemInfo = data.system_info;
            this.isFetching = false;
        })
        .catch(() => {
            AdminService.removeAdmin();
            this.$root.$emit('FooterUpdateEvent');
            this.$router.push({ path: '/' });
            this.isFetching = false;
        });
    }
  },

    mounted: function() {
        this.fetchSystemStatistics();
    },
};
</script>

<style scoped>
h2, h6 {
  color: #343a40; /* Using Bootstrap colors */
}
</style>