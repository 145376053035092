import axios from 'axios';
import ProfileService from '@/services/profile.service.js'

class AdminService {

    appUrl = process.env.VUE_APP_BASE_URL;

    async getAdminStatistics() {
        return axios.get(this.appUrl + "/admin/statistics/")
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    this.removeAdmin();
                }
                throw error;
            });
    }

    async getSystemStatistics() {
        return axios.get(this.appUrl + "/admin/system/")
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    this.removeAdmin();
                }
                throw error;
            });
    }

    async setAdmin() {
        try {
            const response = await ProfileService.getAccountData();
            if (response.data.data.user.is_admin) {
                localStorage.setItem('admin', 'true');
            } else {
                this.removeAdmin();
            }
        } catch {
            this.removeAdmin();
        }
    }

    isAdmin() {
        return localStorage.getItem('admin') === 'true';
    }

    removeAdmin() {
        localStorage.removeItem('admin');
    }

}

export default new AdminService();
