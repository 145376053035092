import axios from 'axios';
import app from '@/main.js';

class AuthService {

    constructor() {
        // Add authorization header to all requests we make
        // with the default axios instance
        axios.interceptors.request.use(request => {
            request.headers.Authorization = this.getAccessToken();
            return request;
        }, error => {
            return Promise.reject(error);
        });

        // Logout user if we detect 401 on any request
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === 401) {
                // Try refreshing the token
                this.refreshToken()
                    .then( () => {
                        app.$router.go();
                    })
                    // If that fails, logout
                    .catch( () => {
                        console.log("Detected 401, logging out...");
                        app.$router.push('/logout');
                    });
            }
            return Promise.reject(error);
        });

        // Create axios instance that won't be intercepted
        this.ax = axios.create();
    }

    login(user) {
        let p = this.ax.post(process.env.VUE_APP_BASE_URL + "/user/login/",
            { username: user.username, password: user.password }
        )
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response.data));
        })

        return p;
    }

    isLoggedIn() {
        if (localStorage.getItem('user') === null) {
            return false;
        }

        return true;
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        let p = this.ax.post(process.env.VUE_APP_BASE_URL + "/user/register/",
            {
                email: user.email,
                password: user.password,
                name: user.firstname,
                gender: user.gender,
                birthdate: user.birthdate,
                invite_code: user.invite_code,
            }
        );

        return p;
    }

    requestPasswordChange(email) {
        let p = this.ax.post(
            process.env.VUE_APP_BASE_URL + "/user/password_reset/request/",
            {email: email}
        );

        return p;
    }

    verifyPasswordResetToken(token) {
        let p = this.ax.post(
            process.env.VUE_APP_BASE_URL + "/user/password_reset/verify/",
            {token: token}
        );

        return p;
    }

    resetPassword(token, new_password) {
        let p = this.ax.post(
            process.env.VUE_APP_BASE_URL + "/user/password_reset/reset/",
            {token: token, password: new_password}
        );

        return p;
    }

    refreshToken() {
        let user = JSON.parse(localStorage.getItem('user'));

        if (!(user && user.refresh)) {
            return null;
        }

        let p = this.ax.post(process.env.VUE_APP_BASE_URL + "/user/login/refresh/",
            { refresh: user.refresh}
        )
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response.data));
        });

        return p;
    }

    getAccessToken() {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.access) {
            return 'Bearer ' + user.access;
        }

        return {};
    }
}

export default new AuthService();