<template>
    <div id="app">
      <div class="inner">
        <router-view/>
        <MainFooter/>
      </div>
    </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue'

export default {
    components: {
        MainFooter,
    }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.inner {
    margin-bottom: 5em;
}
</style>
