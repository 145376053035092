import axios from 'axios';

class ProfileService {

    appUrl = process.env.VUE_APP_BASE_URL;

    getSessionData() {
        return axios.get(this.appUrl + "/session/");
    }

    getAccountData() {
        return axios.get(this.appUrl + "/user/profile/");
    }

}

export default new ProfileService();
