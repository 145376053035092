<template>
    <main>

        <div class="col-6 offset-3">
            <div class="col settings-button">
            </div>

            <div class="text-center pb-4">
                <img class="rounded" src="https://twitch.microtaur.com/static/logo.jpg" alt="Microtaur">
            </div>

            <h1>Service integration</h1>
            <TwitchConnect/>
        </div>

    </main>
</template>

<script>
    import TwitchConnect from '@/components/TwitchConnect.vue'

    export default {
        name: 'Main',
            components: {
                TwitchConnect
            }
    }
</script>

<style scoped>
    main {
        text-align: left;
        font-family: "Inter",sans-serif;

        padding-left: 25px;
        padding-right: 25px;
    }

    h1 {
        padding-left: 5px;
        font-weight: 700;
        font-size: 2.25rem;
    }

    .settings-button {
        padding-left: 10px;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .rounded {
        border-radius: 40px;
    }
</style>