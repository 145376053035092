import axios from 'axios';

class TwitchService {

    appUrl = process.env.VUE_APP_BASE_URL;

    getStatus() {
        return axios.get(this.appUrl + "/twitch/token/");
    }


    getStateCode() {
        // Won't do anything if code's already generated
        this._setRandomStateCode();
        return localStorage.getItem('stateCode');
    }


    clearStateCode() {
        localStorage.removeItem('stateCode');
    }


    getRedirectUrl() {
        return process.env.VUE_APP_REDIRECT_URL;
    }


    async getWatchUrl(id) {
        return axios.get(this.appUrl + "/twitch/watch/" + id + "/");
    }

    _setRandomStateCode() {
        const c = this._getRandomStateCode();
        if (localStorage.getItem('stateCode') === null) {
            localStorage.setItem('stateCode', c);
        }
    }


    _getRandomStateCode() {
        const length = 32;

        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
       }
       return result;
    }


    sendAuth(code) {
        return axios.post(
            this.appUrl + "/twitch/authorize/",
            {'authorization_code': code}
        );
    }


}

export default new TwitchService();