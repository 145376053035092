<template>

<div class="mb-3 text-center">
  <div class="col">

    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">Gameplay statistics</h4>
      </div>
      <div class="card-body">
        <ul>
            <li><b>Sessions played:</b> {{ gameplay.sessions }}</li>
            <li><b>Gameplay events:</b> {{ gameplay.events }}</li>
        </ul>
      </div>
    </div>

    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">Account details</h4>
      </div>
      <div class="card-body">
        <ul>
            <li><b>Email:</b> {{ profile.email }}</li>
            <li><b>Name:</b> {{ profile.name }}</li>
        </ul>
      </div>
    </div>

  </div>
</div>

</template>

<script>
import ProfileService from '@/services/profile.service.js';

    export default {
        name: 'Profile',

        data () {
            return {
                gameplay: {
                    sessions: null,
                    events: null,
                },
                profile : {
                    email: null,
                    name: null,
                },
            }
        },

        mounted: function() {
            ProfileService.getSessionData()
            .then(response => {
                this.gameplay.sessions = response.data['session_count'];
                this.gameplay.events = response.data['events_count'];
            })

            ProfileService.getAccountData()
            .then(response => {
                this.profile.email = response.data['data']['user']['email'];
                this.profile.name = response.data['data']['name'];
            })
        }
    }
</script>

<style scoped>
    ul {
        text-align: left;
    }
</style>
