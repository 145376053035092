<template>
    <div class="container mt-3">
        <b-alert v-model="showRegisterSuccessAlert" variant="success">Register successful!</b-alert>
        <b-alert v-model="showFailedLoginAlert" variant="danger">{{ failedLoginMessage}}</b-alert>
        <section id="cover">
        <div id="cover-caption">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                        <img src="https://microtaur.com/user/themes/microtaur/images/logo.png" alt="Microtaur">
                        <h1 class="mt-2">Intermission Games</h1>
                        <h2 class="mb-4">Sign in</h2>
                        <div class="px-2">
                            <form @submit.prevent="login" class="justify-content-center">
                                <div class="form-group mb-2">
                                    <input required v-model="username" type="email" placeholder="user@example.com" class="form-control">
                                </div>
                                <div class="form-group mb-3">
                                    <input required v-model="password" type="password" placeholder="password" class="form-control">
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>

        <RegisterForm></RegisterForm>
        <ResetPasswordForm></ResetPasswordForm>

    </div>
 
</template>

<script>
import Vue from 'vue'
import AuthService from '@/services/auth.service.js'
import AdminService from '@/services/admin.service.js'

import { BAlert } from 'bootstrap-vue'
import RegisterForm from './RegisterForm'
import ResetPasswordForm from './ResetPasswordForm'

Vue.component('b-alert', BAlert);
Vue.component('RegisterForm', RegisterForm);
Vue.component('ResetPasswordForm', ResetPasswordForm);

async function onLoginSuccess(context) {
    await AdminService.setAdmin();
    context.$root.$emit('FooterUpdateEvent');

    context.showFailedLoginAlert = false;
    context.$router.push({ path: '/' })
}

function onLoginFailed(context, error) {
    if (!error.response) {
        context.failedLoginMessage = 'Check your connection'
        context.showFailedLoginAlert = true;
        return;
    }
    if (error.response.status == 401) {
        context.failedLoginMessage = 'Incorrect login details'
        context.showFailedLoginAlert = true;
    }
}


export default {
    name: 'LoginForm',
    data () {
        return {
            username: null,
            password: null,
            showFailedLoginAlert: false,
            failedLoginMessage: 'Internal error',

            showRegisterSuccessAlert: false
        }
    },

    methods: {
        async login() {
            try {
                const response = await AuthService.login({ username: this.username, password: this.password });
                onLoginSuccess(this, response);
            } catch (error) {
                onLoginFailed(this, error);
            }
        }
    },

    created: function() {
        this.$on('register-success', () => { this.showRegisterSuccessAlert = true; });
    }

}
</script>