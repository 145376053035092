<template>
    <footer v-if="shouldShowNavbar == true">
        <nav class="navbar fixed-bottom bg-gray">
            <div class="col-12">
                <div class="row">
                    <div class="col navbar-button text-center">
                        <router-link to="/" exact>
                            <ion-icon name="home-outline"></ion-icon>
                        </router-link>
                    </div>
                    <div class="col navbar-button text-center">
                        <router-link to="/profile">
                            <ion-icon name="person-circle-outline"></ion-icon>
                        </router-link>
                    </div>
                    <div class="col navbar-button text-center" v-if="isAdmin">
                        <router-link to="/admin">
                            <ion-icon name="hammer-outline"></ion-icon>
                        </router-link>
                    </div>
                    <div class="col navbar-button text-center">
                        <router-link to="/logout">
                            <ion-icon name="log-out-outline"></ion-icon>
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>

            
    </footer>
</template>

<script>
    import Vue from 'vue';
    import AdminService from '@/services/admin.service.js'

    Vue.config.ignoredElements = ['ion-icon']

    export default {
        name: 'MainFooter',

        data () {
            return {
                isAdmin: false,
            }
        },

        computed: {
            shouldShowNavbar() {
                const noNavbarPages = ['/login', '/reset'];
                return !noNavbarPages.some(page => this.$route.path.startsWith(page));
            }
        },

        mounted: function () {
            this.$root.$on('FooterUpdateEvent', () => {
                this.checkIfAdmin();
            })

            this.checkIfAdmin();
        },

        methods: {
            async checkIfAdmin() {
                this.isAdmin = await AdminService.isAdmin();
            },

        }

    }
</script>

<style scoped>
    ion-icon {
        color: #464646;
        font-size: 2em;
    }

    .bg-gray {
        background: #FAFAFA;
        border-top-color: #E8E8E8;
        border-top-width: 1px;
        border-top-style: solid;
    }

    .row {
        margin: 0;
        padding: 0;

        padding-left: 0.25em;
        padding-right: 0.25em;
    }

    .navbar-button {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }

    .navbar-icon path {
        font-weight: 500;
    }

    .router-link-active ion-icon {
        color: #1E74FD !important;
    }
</style>