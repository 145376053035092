<template>
    <div></div>
</template>

<script>
import AuthService from '@/services/auth.service.js';

export default {
    created: function() {
        AuthService.logout();
        this.$router.push('/').catch(() => {});
    }
}
</script>
