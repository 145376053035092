import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from '@/services/auth.service.js'
import Main from '@/views/Main.vue'
import Profile from '@/views/Profile.vue'
import Admin from '@/views/Admin.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import PasswordReset from '@/views/PasswordReset.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/connect',
        name: 'Connect',
        component: Main
    },
    {
        path: '/reset/:token',
        name: 'PasswordReset',
        component: PasswordReset
    }
    //{ path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in
    const publicPages = ['/login', '/reset'];
    const authRequired = !publicPages.some(publicPath => to.path.startsWith(publicPath));
    const loggedIn = AuthService.isLoggedIn();

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
})

export default router
