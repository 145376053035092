<template>
    <div class="container mt-3">
        <section id="cover">
        <div id="cover-caption">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                        <img src="https://microtaur.com/user/themes/microtaur/images/logo.png" alt="Microtaur">
                        <h1 class="mt-2">Intermission Games</h1>
                        <h2 class="mb-4">Reset password</h2>
                        <div v-if="tokenValid === null" class="spinner-grow text-primary" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div class="px-2" v-else-if="tokenValid === true">

                            <div v-if="showSuccess">
                                <b-alert variant="success" show>Password changed.<br><a href="/">Return to the main page.</a></b-alert>
                            </div>
                            <div v-else>
                                <b-alert variant="danger" v-model="showError" v-html="errorMessage"></b-alert>

                                <form @submit.prevent="reset" class="justify-content-center">
                                    <div class="form-group mb-2">
                                        <input required v-model="password" type="password" placeholder="new password" class="form-control">
                                    </div>
                                    <div class="form-group mb-3">
                                        <input required v-model="passwordConfirm" type="password" placeholder="confirm new password" class="form-control">
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-lg">Reset password</button>
                                </form>
                            </div>
                        </div>
                        <div v-else>
                            <b-alert variant="danger" show>This link is invalid.<br><a href="/">Return to the main page.</a></b-alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>

    </div>
</template>

<script>
import AuthService from '@/services/auth.service.js'

export default {
    name: 'PasswordReset',
    components: {

    },
    data () {
        return {
            password: null,
            passwordConfirm: null,
            showError: false,
            showSuccess: false,
            errorMessage: "",

            tokenValid: null,
        }
    },
    mounted: function() {
        const token = this.$route.params.token;
        AuthService.verifyPasswordResetToken(token)
            .then(() => {
                this.tokenValid = true;
            })
            .catch(() => {
                this.tokenValid = false;
            });
    },
    methods: {
        reset() {
            if (this.password !== this.passwordConfirm) {
                this.showError = true;
                this.errorMessage = "Passwords do not match!";
                return;
            } else {
                this.showError = false;
            }

            const token = this.$route.params.token;
            AuthService.resetPassword(token, this.password)
            .then(() => {
                this.showError = false;
                this.showSuccess = true;
            })
            .catch(error => {
                this.errorMessage = "<ul class=\"list-group\">"
                error.response.data.error.password.forEach((error) => {
                  this.errorMessage += `<li class="list-group-item list-group-item-danger">${error}</li>`;
                });
                this.errorMessage += "</ul>"
                this.showError = true;
            });

        }
    },
}


</script>
