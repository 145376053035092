<template>
    <main>
        <AdminStatistics/>
        <AdminSystem/>
    </main>

</template>

<script>
import AdminStatistics from '@/components/AdminStatistics.vue';
import AdminSystem from '@/components/AdminSystem.vue';

export default {
    name: 'Admin',
    components: {
        AdminStatistics,
        AdminSystem,
    }
}
</script>

<style scoped>
    main {
        text-align: left;
        font-family: "Inter",sans-serif;

        padding-left: 25px;
        padding-right: 25px;
    }

    h1 {
        padding-left: 5px;
        font-weight: 700;
        font-size: 2.25rem;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 700;
    }
</style>