<template>

<div>
    <p class="text-center">Don't have an account? <a href="#" @click.prevent v-b-modal.modal-center>Register now!</a></p>

    <b-modal id="modal-center" hide-header hide-footer centered>

        <b-alert v-model="showError" variant="danger">{{ errorMessage }}</b-alert>

        <section id="cover">
        <div id="cover-caption">
            <div class="container">
                <div class="row">
                    <div class="mx-auto text-center form p-4">
                        <h1 class="py-2 text-truncate">Register</h1>
                        <div class="px-2">
                            <form @submit.prevent="register" class="justify-content-center">
                                <div class="form-group mb-2">
                                    <input required v-model="username" type="email" placeholder="user@example.com" class="form-control">
                                </div>
                                <div class="form-group mb-2">
                                    <input required v-model="password" type="password" placeholder="password" class="form-control">
                                </div>
                                <div class="form-group mb-2">
                                    <input required v-model="inviteCode" type="text" placeholder="invite code" class="form-control" minlength="2">
                                </div>
                                <div class="form-group mb-2">
                                    <input required v-model="firstname" type="text" placeholder="first name" class="form-control" minlength="2">
                                </div>
                                <div class="form-group mb-2">
                                    <select v-model="gender" class="form-control form-select" name="gender" required>
                                        <option value="M" selected>Male</option>
                                        <option value="F">Female</option>
                                        <option value="O">Other</option>
                                    </select>
                                </div>
                                <div class="form-group mb-2">
                                    <input v-model="birthdate" class="form-control" type="date" required>
                                </div>
                                <div class="form-group text-left mb-4 d-flex">
                                    <div class="me-2 my-auto">
                                        <input class="form-check-input" type="checkbox" value="" required>
                                    </div>
                                    <div class="ms-2 my-auto">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I accept the <a href="https://microtaur.com/terms-of-service-ig" target="_blank">Terms of Use</a> and <a href="https://microtaur.com/privacy-policy-ig" target="_blank">Privacy Policy</a> of the service.
                                        </label>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">Create account</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>

    </b-modal>
</div>

</template>

<script>
import AuthService from '@/services/auth.service.js'
import { BModal, VBModal } from "bootstrap-vue";

export default {
    name: 'RegisterForm',
    components: {
        BModal,
    },
    directives: {
        'b-modal': VBModal
    },
    data () {
        return {
            username: null,
            password: null,
            firstname: null,
            gender: 'M',
            birthdate: null,
            inviteCode: null,

            showError: false,
            errorMessage: "Internal error",
        }
    },
    methods: {
        register() {
            AuthService.register(
                {
                    email: this.username,
                    password: this.password,
                    firstname: this.firstname,
                    gender: this.gender,
                    birthdate: this.birthdate,
                    invite_code: this.inviteCode,
                }
            )
            .then(response => onRegisterSuccess(this, response))
            .catch(error => onRegisterFailed(this, error));
        }
    }
}


function onRegisterSuccess(context) {
    context.$root.$emit('bv::hide::modal', 'modal-center');
    context.$parent.$emit('register-success');
}


function onRegisterFailed(context, error) {
    if ('password' in error.response.data) {
        context.errorMessage = "Password does not meet requirements: must contain at least one lowercase character, one numeric character, and can't be shorter than 6 characters.";
    }
    else if ('email' in error.response.data) {
        if (error.response.data['email'].find(s => s.includes("exists"))) {
            context.errorMessage = "User already exists!";
        } else {
            context.errorMessage = "Provided email address is incorrect";
        }
    }
    else if ('birthdate' in error.response.data) {
        if (error.response.data['birthdate'].find(s => s.includes("too young"))) {
            context.errorMessage = "You must be 18 years old in order to register.";
        }
        if (error.response.data['birthdate'].find(s => s.includes("too old"))) {
            context.errorMessage = "Incorrect birthdate provided.";
        }
    }
    else if ('invite_code' in error.response.data) {
        if (error.response.data['invite_code'].find(s => s.includes("Incorrect"))) {
            context.errorMessage = "Provided invite code is incorrect.";
        }
    }
    else {
        console.log(error.response);
    }

    context.showError = true;
}


</script>

<style>
    a {
    color: #42b983;
    }

    .text-left {
        text-align: left !important;
    }

    .form-check-input  {
        text-align: left;
    }
</style>