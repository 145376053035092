<template>

<div>
    <p class="text-center">Can't login? <a href="#" @click.prevent v-b-modal.modal-reset-password>Reset your password.</a></p>

    <b-modal id="modal-reset-password" hide-header hide-footer centered>

        <b-alert v-model="showSuccess" variant="success">Email with reset link sent!</b-alert>
        <b-alert v-model="showError" variant="danger">{{ errorMessage }}</b-alert>

        <section id="cover">
        <div id="cover-caption">
            <div class="container">
                <div class="row">
                    <div class="mx-auto text-center form p-4">
                        <h1 class="py-2 text-truncate">Reset password</h1>
                        <div class="px-2">
                            <form @submit.prevent="requestResetLink" class="justify-content-center">
                                <div class="form-group mb-4 mt-3">
                                    <input required v-model="email" type="email" placeholder="user@example.com" class="form-control">
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">Send reset link</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>

    </b-modal>
</div>

</template>

<script>
import AuthService from '@/services/auth.service.js'
import { BModal, VBModal } from "bootstrap-vue";

export default {
    name: 'ResetPasswordForm',
    components: {
        BModal,
    },
    directives: {
        'b-modal': VBModal
    },
    data () {
        return {
            email: null,

            showSuccess: false,
            showError: false,
            errorMessage: "Internal error",
        }
    },
    methods: {
        requestResetLink() {
            AuthService.requestPasswordChange(this.email)
                .then(() => {
                    this.showSuccess = true;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}




</script>

<style>
    a {
    color: #42b983;
    }

    .text-left {
        text-align: left !important;
    }

    .form-check-input  {
        text-align: left;
    }
</style>